/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(to bottom, #00b4d8, #0077b6);
  color: #fff;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Navbar Styles */
.navbar {
  background-color: #0077b6;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
}

.logoimg {
  width: 350px;
  height: 70px;
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 1em;
}

.nav-links li {
  position: relative; /* Required for dropdown */
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.nav-links a:hover {
  background-color: #005f8f;
  border-radius: 5px;
}

/* Dropdown Menu */
.dropdown-content {
  display: none;
  position: absolute;
  top: 100%; /* Position below the main link */
  left: 0;
  background-color: #0097b6;
  padding: 20px 0;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.dropdown-content a {
  display: block;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-content a:hover {
  background-color: #005f8f;
}

/* Show Dropdown on Hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Centered Content */
.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.page-container {
  text-align: center;
  max-width: 600px;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    gap: 1em;
  }
}

/* Left-Aligned Styles for Components */
.left-aligned {
  text-align: left;
  padding: 5px; /* Add padding for a cleaner look */
}

.left-aligned h1 {
  margin-bottom: 20px;
}

.left-aligned ul {
  list-style-type: disc;
  margin-left: 20px; /* Indent for bullet points */
}

.left-aligned p {
  margin-bottom: 15px;
}

.left-aligned section {
  margin-bottom: 20px; /* Space between sections */
}

img {
    max-width: 100%;
    max-height: 100%;
}

.quiz-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.quiz-container h1 {
  text-align: center;
  color: #0077b6;
}

.quiz-container ol {
  margin: 20px 0;
  padding-left: 20px;
  font-size: 1.2em;
}

.quiz-container li {
  margin-bottom: 15px;
}

.quiz-container input {
  width: 50%;
  padding: 8px;
  margin-top: 5px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.quiz-container button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1em;
  background: #0077b6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.score-section {
  margin-top: 20px;
  text-align: center;
}

.score-section button {
  margin-top: 10px;
}

.score-section {
  margin-top: 20px;
  text-align: center;
}

.score-text {
  font-size: 1.5em;
  font-weight: bold;
  color: #0077b6;
  margin-bottom: 15px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.score-text.perfect {
  color: #28a745; /* Green for perfect score */
  animation: pop-scale 0.5s ease-in-out;
}

@keyframes pop-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.choice {
  padding: 5px;
}

.solution {
  padding: 5px;
}

